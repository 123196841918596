<template>
  <div v-if="mobileWidth">
    <mobile />
  </div>
  <div class="homePage" v-else>
    <div class="banner">
      <div class="item">
        <img :src="dataList[currentIndex]" />
      </div>
      <div class="page" v-if="this.dataList.length > 1"></div>
      <div class="banner-content" v-if="0 === currentIndex">
        <div class="title">
          Gain REAL business results with a VIRTUAL storefront.
        </div>
        <div class="sub-title">
          With solutions and services to optimize business and communication
          efficiencies, Connexima is helping get past the limitations of
          traditional trading approaches, and solve your toughest business
          challenge.
        </div>
        <div class="btn" @click="direct('user-action/register')">
          <button>
            Free Sign Up
          </button>
        </div>
      </div>
      <div class="banner-content" v-else-if="1 === currentIndex">
        <div class="title">
          A transparent database with over 95k chemicals, ingredients and
          polymers
        </div>
        <div class="sub-title">
          Make informed sourcing decisions and find the exact product you need.
          Simply enter the name of the product that you are looking for, and you
          may select your provider from an extensive list of end-user customers,
          factories, exporters and importers.
        </div>
        <div class="btn" @click="direct('data-search')">
          <button>
            Search database
          </button>
        </div>
      </div>
      <div class="banner-content" v-else-if="2 === currentIndex">
        <div class="title">Fast. Efficient. Reliable.</div>
        <div class="sub-title">
          Receive direct shipments from the U.S. warehouse
        </div>
        <div class="btn" @click="direct('products')">
          <button>
            View products
          </button>
        </div>
      </div>
    </div>

    <div class="reach sc-width">
      <div class="pic">
        <img src="@/assets/sell/reach-1.png" alt="" />
      </div>
      <div class="reach-content">
        <div class="title">Reach. Connect. Grow</div>
        <div class="main-content">
          <div class="item">
            <div class="pic">
              <img src="@/assets/sell/reach-2.png" alt="" />
            </div>
            <div class="word">
              List
              <span style="font-weight:900;font-size:27px"
                >unlimited products</span
              >
              online for more sales opportunities
            </div>
          </div>
          <div class="item">
            <div class="pic">
              <img src="@/assets/sell/reach-2.png" alt="" />
            </div>
            <div class="word">
              Announce and
              <span style="font-weight:900;font-size:27px"
                >promote new products</span
              >
              to potential buyers
            </div>
          </div>
          <div class="item">
            <div class="pic">
              <img src="@/assets/sell/reach-2.png" alt="" />
            </div>
            <div class="word">
              <span style="font-weight:900;font-size:27px"
                >Highlight your brand</span
              >
              and key products among competitors
            </div>
          </div>
          <div class="item">
            <div class="pic">
              <img src="@/assets/sell/reach-2.png" alt="" />
            </div>
            <div class="word">
              <span style="font-weight:900;font-size:27px"
                >Expand your business globally</span
              >
              and stand out in the market
            </div>
          </div>
        </div>
        <router-link to="/exhibitors">
          <div class="button search-button">Discover existing storefronts</div>
        </router-link>
      </div>
    </div>

    <div class="blue">
      <div class="top sc-width">
        <div class="item">
          <div class="pic">
            <img src="@/assets/sell/MaxQuote.png" alt="" />
          </div>
          <div class="word">
            Submit MaxQuote
          </div>
        </div>
        <div class="item">
          <div class="pic">
            <img src="@/assets/sell/Exchange-business-cards.png" alt="" />
          </div>
          <div class="word">
            Exchange Business Cards
          </div>
        </div>
        <div class="item">
          <div class="pic">
            <img src="@/assets/sell/List-unlimited-products.png" alt="" />
          </div>
          <div class="word">
            List Unlimited Products & Documents
          </div>
        </div>
      </div>
      <div class="bottom sc-width">
        <div class="item">
          <div class="pic">
            <img src="@/assets/sell/Promote-new-products.png" alt="" />
          </div>
          <div class="word">
            Promote New Products
          </div>
        </div>
        <div class="item">
          <div class="pic">
            <img src="@/assets/sell/Send-instant-messages.png" alt="" />
          </div>
          <div class="word">
            Send Instant Messages,Files & Videos
          </div>
        </div>
        <div class="item">
          <div class="pic">
            <img src="@/assets/sell/Receive-sample-requests.png" alt="" />
          </div>
          <div class="word">
            Receive Sample Request & Inquiry
          </div>
        </div>
      </div>
    </div>
    <div class="steps ">
      <div class="title">
        Three simple steps to sign-up for your FREE Connexima storefront
      </div>
      <div class="content sc-width">
        <div class="item first">
          <div class="count">1.</div>
          <div class="name">Create Account Login</div>
          <div class="pic">
            <img src="@/assets/sell/Promote-new-products.png" alt="" />
          </div>
        </div>
        <div class="item second">
          <div class="count">2.</div>
          <div class="name">Enter Company Info</div>
          <div class="pic">
            <img src="@/assets/sell/company-info.png" alt="" />
          </div>
        </div>
        <div class="item third">
          <div class="count">3.</div>
          <div class="name">Select Product Category</div>
          <div class="pic">
            <img src="@/assets/sell/product-catagory.png" alt="" />
          </div>
        </div>
      </div>
      <router-link to="/user-action/register">
        <div class="btn search-button">
          Join now and meet new customers today!
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import banner from "@/assets/sell/banner.jpg";
import { mapState } from "vuex";
import mobile from "./sell-mobile";

export default {
  // created: {
  //    this.queryRecommendExhibitor();
  //  },
  computed: {
    ...mapState("user", ["token", "role"]),
    //上一张
    prevIndex() {
      if (this.currentIndex == 0) {
        return this.dataList.length - 1;
      } else {
        return this.currentIndex - 1;
      }
    },
    //下一张
    nextIndex() {
      if (this.currentIndex == this.dataList.length - 1) {
        return 0;
      } else {
        return this.currentIndex + 1;
      }
    }
  },
  components: {
    mobile
  },
  data() {
    return {
      mobileWidth: window.innerWidth < 800,
      dataList: [banner],
      currentIndex: 0,
      timer: null,
      // role: "" // attendee exhibitor
      activeIndex: null,
      recommendCompany: [],
      sourcingFirst: "",
      sourcingSecond: "",
      sourcingThird: ""
    };
  },

  mounted() {
    // this.timer = setInterval(() => {
    //     this.gotoPage(this.nextIndex)
    // }, 6000)
    // this.queryRecommendExhibitor();
  },
  methods: {
    gotoPage(index) {
      this.currentIndex = index;
    },
    runInv() {
      this.timer = setInterval(() => {
        this.gotoPage(this.nextIndex);
      }, 1000);
    },
    homeWithRedirect() {
      this.$router.push(`/index`);
    },
    dataSearchWithRedirect() {
      this.$router.push(`/data-search`);
    },
    productsWithRedirect() {
      this.$router.push(`/products`);
    },
    exhibitorsWithRedirect() {
      this.$router.push(`/exhibitors`);
    },
    sendInquiry() {
      if ("" === this.role) {
        this.$router.push("/user-action/login");
      } else {
        this.$router.push({
          path: "/sourcing-inquiry",
          query: {
            first: this.sourcingFirst,
            second: this.sourcingSecond,
            third: this.sourcingThird
          }
        });
      }
    },
    companyMouseOver(e) {
      this.activeIndex = e;
    },
    companyMouseLeave() {
      this.activeIndex = null;
    },

    direct(route) {
      this.$router.push("/" + route);
    }
  },
  filters: {}
};
</script>

<style lang="less" scoped>
@media (min-width: 1600px) {
  .banner .banner-content {
    .title {
      font-size: 57px;
    }
    .sub-title {
      font-size: 28px;
      max-width: 1500px;
      margin: 0 auto;
    }
    .btn {
      button {
        font-size: 30px;
      }
    }
  }
}
@media (max-width: 1600px) and (min-width: 1400px) {
  .banner .banner-content {
    .title {
      font-size: 49px;
    }
    .sub-title {
      font-size: 27px;
      max-width: 1200px;
      margin: 0 auto;
    }
    .btn {
      button {
        font-size: 26px;
      }
    }
  }

  .banner .banner-content {
    top: 115px !important;
  }
  .reach {
    .reach-content {
      margin-top: 0 !important;
      .title {
        margin-bottom: 15px !important;
      }
    }
  }
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .banner .banner-content {
    .title {
      font-size: 52px;
    }
    .sub-title {
      font-size: 28px;
      max-width: 1200px;
      margin: 0 auto;
    }
    .btn {
      button {
        font-size: 27px;
      }
    }
  }
  .banner .banner-content {
    top: 90px !important;
  }
}
@media (max-width: 1200px) {
  .banner .banner-content {
    .title {
      font-size: 56px;
    }
    .sub-title {
      font-size: 26px;
    }
    .btn {
      button {
        font-size: 26px;
      }
    }
  }
}

* {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
  float: left;
  width: 30px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
}
.homePage {
  background: #ffffff;
  margin-top: 65px;
}
.navs {
  background: #ffffff;
  .content {
    display: flex;
    height: 84px;
    /*width:1500px;*/
    margin: 0 auto;
    align-items: center;
    .nav {
      margin: 20px;
      font-size: 20px;
      font-weight: 700;
      color: #53575c;
      line-height: 17px;
      cursor: pointer;
    }
    .home {
      margin-left: 0px;
    }
    .active {
      color: #ef842d;
      cursor: auto;
    }
  }
}
.banner {
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  top: 85px;
}
.banner img {
  width: 100%;
  display: block;
  max-height: 700px;
  filter: brightness(0.7);
}
.banner .page {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
}
.banner .page ul {
  float: right;
}
.banner .banner-content {
  text-align: center;
  margin: auto;
  position: absolute;
  top: 190px;
  left: 0;
  bottom: 0;
  right: 0;
  color: #252729;

  .title {
    font-weight: 400;
    color: #ffffff;
  }
  .sub-title {
    font-weight: 400;
    color: #ffffff;
  }
  .btn {
    button {
      color: #ffffff;
      background: linear-gradient(90deg, #f5a210, #ef8126);
      width: 320px;
      border: none;
      border-radius: 50px;
      height: 60px;
      margin-top: 30px;
      cursor: pointer;
    }
  }
}
.current {
  color: #ff6700;
}
.reach {
  margin: 120px auto 50px auto;
  display: flex;
  justify-content: center;
  .pic {
    img {
      width: 400px;
    }
  }
  .reach-content {
    margin: 30px 0px 50px 40px;
    .title {
      font-size: 42px;
      color: #ef7f21;
      font-weight: 800;
      margin-bottom: 30px;
    }
    .main-content {
      .item {
        display: flex;

        .pic {
          margin-right: 15px;
          img {
            width: 27px;
            height: 37px;
          }
        }
        .word {
          font-size: 25px;
        }
      }
    }
    .button {
      background: #ef7f21;
      color: #ffffff;
      font-size: 25px;
      text-align: center;
      border-radius: 8px;
      width: 400px;
      height: 45px;
      padding-top: 5px;
      margin-top: 30px;
    }
  }
}
.blue {
  background: #3c3853;
  .top {
    padding-top: 50px;
    display: flex;
    margin: 0 auto;
    justify-content: space-around;
    .item {
      margin: 20px 50px 10px 50px;
      .pic {
        text-align: center;
        img {
          width: 130px;
          height: 130px;
        }
      }
      .word {
        font-size: 22px;
        color: #ffffff;
        max-width: 290px;
      }
    }
  }
  .bottom {
    display: flex;
    margin: 0 auto;
    padding-bottom: 50px;
    justify-content: space-around;
    .item {
      margin: 20px 50px 10px 50px;
      .pic {
        text-align: center;
        img {
          width: 130px;
          height: 130px;
        }
      }
      .word {
        font-size: 22px;
        color: #ffffff;
        max-width: 290px;
      }
    }
  }
}
.steps {
  margin-top: 50px;
  padding-bottom: 50px;
  .title {
    font-size: 42px;
    text-align: center;
    font-weight: 800;
    max-width: 800px;
    margin: 0 auto 30px auto;
  }
  .content {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    .item {
      width: 33.33%;
      height: 230px;
      padding-top: 20px;
      .count {
        text-align: center;
        font-size: 30px;
        color: #ffffff;
        font-weight: 800;
      }
      .name {
        text-align: center;
        font-size: 24px;
        color: #ffffff;
        padding-top: 15px;
      }
      .pic {
        text-align: center;
        padding-top: 15px;
        img {
          width: 80px;
          height: 80px;
        }
      }
    }
    .first {
      background: #f5915f;
    }
    .second {
      background: #f4834b;
    }
    .third {
      background: #f27437;
    }
  }
  .btn {
    margin: 30px auto 0px auto;
    text-align: center;
    border: 2px solid #ef7f21;
    border-radius: 5px;
    width: 630px;
    color: #ef7f21;
    font-size: 28px;
  }
}
</style>
